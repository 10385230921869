import React from "react";
import ReactDOM from "react-dom/client";

import App from "./entry/App";
import "./sass/index.scss";
import Clarity from "@microsoft/clarity";
import { idClarityDev, idClarityProd, idClarityQa, idClarityStg } from "../src/utils/env";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
switch (process.env.REACT_APP_API_URL) {
  case "https://api.fasterwayservice.com/": // prod
    Clarity.init(idClarityProd ?? "");
    break;
  case "https://apistg.fasterwayservice.com/": // stg
    Clarity.init(idClarityStg ?? "");
    break;
  case "https://apiqa.fasterwayservice.com/": // qa
    Clarity.init(idClarityQa ?? "");
    break;
  case "https://apidev.fasterwayservice.com/": // dev
    Clarity.init(idClarityDev ?? "");
    break;
  default:
    break;
}
