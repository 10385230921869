import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainCard from "../../components/MainCard/MainCard";
import Table from "../../components/Table/Table";
import { IClientCSVData, IMyClientsParams, IRowMyClientsReports } from "../../constants/interfaces";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { ClientsStore } from "../../store/MyClientsStore";
import { ROUTE_PATHS } from "../../constants/routePaths";
import { Column, columnTypes, TableOptions } from "../../components/DataTable/datatable.models";
import { Box } from "@mui/material";
import { getSnapshot, Instance } from "mobx-state-tree";
import { ContextModel } from "../../models/Common";
import { DateTime } from "luxon";

const StarReport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const prevParams = location.state && location.state.keepParams ? getSnapshot(ClientsStore.params) : undefined;
  const pageWasRendered = useRef(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [param, setParam] = useState<IMyClientsParams>({
    typeReport: 2,
    startDate: prevParams ? prevParams.startDate : undefined,
    endDate: prevParams ? prevParams.endDate : undefined,
  });
  const [rows, setRows] = useState<IRowMyClientsReports[] | []>([]);
  const [context, setContext] = useState<Instance<typeof ContextModel> | null>();

  /**
   * columns object to be passed to the Table component with the settings for each one.
   */
  const columns: Column[] = [
    {
      name: "Full Name",
      value: "fullName",
      sortable: false,
      mobile: true,
    },
    {
      name: "Stars",
      value: "stars",
      sortable: false,
      mobile: true,
    },
    {
      name: "Email",
      value: "email",
      sortable: false,
      mobile: false,
    },
    {
      name: "Phone",
      value: "phone",
      sortable: false,
      mobile: false,
    },
    {
      name: "",
      value: "",
      type: columnTypes.CUSTOM,
      renderCell: () => <RemoveRedEyeOutlined sx={{ fontSize: "2rem" }} className="icon-column" />,
      mobile: true,
    },
  ];

  /**
   * It will be called on every row click
   */
  const onRowClicked = (row: object) => {
    navigate(`${ROUTE_PATHS.MY_CLIENTS_TABS.replace(":id", (row as IRowMyClientsReports)?.id.toString())}`, {
      state: { backLinkText: "Client Report", backLinkUrl: ROUTE_PATHS.STAR_REPORT },
    });
  };

  /**
   * It will be called every time the sorting or the pagination changed.
   */
  const onTableOptionsChanged = (tableParams: TableOptions) => {
    //save table pagination and sorting
    setParam({
      ...param,
      page: tableParams.page,
      size: tableParams.pageSize,
    });
  };

  const getDate = (startDate: string | undefined, endDate: string | undefined) => {
    setParam({ ...param, startDate, endDate });
  };

  const getDataForCSV = async (): Promise<IClientCSVData[]> => {
    const paramToCSV = { ...param, page: undefined, size: undefined, withoutPagination: true };
    const data = await ClientsStore.fetchClientReports(paramToCSV, true);
    return data ?? [];
  };

  const getStarReport = async () => {
    try {
      const result = await ClientsStore.fetchClientReports(param);
      setRows(result.items);
      setContext(result.context);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    if (pageWasRendered.current) {
      const fetchData = async () => {
        setLoadingTable(true);
        try {
          await getStarReport();
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingTable(false);
        }
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  useEffect(() => {
    pageWasRendered.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainCard>
      <Table
        getCSVData={getDataForCSV}
        idTable="star-table"
        header={
          <Box
            sx={{
              padding: 1.25,
              fontSize: "1.4rem",
              textAlign: "left",
              marginBottom: 0.5,
              borderBottom: 2,
              borderColor: "divider",
            }}
          >
            <span style={{ fontWeight: "bold", fontSize: "16px" }}>Star Report:</span>
            {" Default shows all Stars. Set a start and end date to view a specific time period."}
          </Box>
        }
        tableProps={{
          columns: columns,
          data: rows,
          loading: loadingTable,
          totalRows: context?.totalRows ?? 0,
          handleTableOptionsChanged: onTableOptionsChanged,
          handleRowClicked: onRowClicked,
          expandRowOnMobileBreakpoint: true,
          mobileBreakpoint: 900,
          paginationValue:
            prevParams && prevParams.page && prevParams.size
              ? {
                  page: prevParams.page - 1,
                  size: prevParams.size,
                }
              : undefined,
        }}
        calendarProps={{
          withCalendarRange: true,
          sx: { maxWidth: "18rem" },
          customOnChange: getDate,
          startDate: prevParams?.startDate ? DateTime.fromISO(prevParams.startDate) : undefined,
          endDate: prevParams?.endDate ? DateTime.fromISO(prevParams.endDate) : undefined,
        }}
        downloadCSVButtonProps={{
          id: "client-btn-dowload",
          show: true,
          text: "Download",
          disabled: rows?.length === 0 ? true : false,
          startIcon: <SaveAltIcon />,
          variant: "outlined",
          size: "large",
        }}
      />
    </MainCard>
  );
};

export default StarReport;
