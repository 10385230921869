import { ColumnDef } from "@tanstack/react-table";
import { Column, columnTypes } from "./datatable.models";
import { formatDate, formatMinutesToHours } from "../../utils/date";
import IconButton from "@mui/material/IconButton";
import { AddOutlined, RemoveOutlined } from "@mui/icons-material";

export function useNormalizeColumns(columns: Column[], expandableRows: boolean) {
  const normalizeColumns = (columns: Column[]) => {
    const columnHelper: ColumnDef<object>[] = [];
    if (expandableRows) {
      columnHelper.push({
        id: "expander",
        header: () => null,
        enableSorting: false,
        meta: {
          align: "left",
          mobile: true,
        },
        cell: ({ row }) => {
          return (
            row.getCanExpand() && (
              <IconButton
                style={{ padding: "0px 0px 0px 0.2rem" }}
                {...{
                  onClick: row.getToggleExpandedHandler(),
                }}
              >
                {row.getIsExpanded() ? (
                  <RemoveOutlined sx={{ fontSize: "2rem" }} />
                ) : (
                  <AddOutlined sx={{ fontSize: "2rem" }} />
                )}
              </IconButton>
            )
          );
        },
      });
    }
    columns.map((col: Column) => {
      switch (col.type) {
        case columnTypes.DATE:
          columnHelper.push({
            accessorKey: col.value,
            header: col.renderHeader ? col.renderHeader : col.name ? col.name : " ",
            enableSorting: col.sortable,
            meta: {
              mobile: col.mobile,
              minWidth: col.minWidth ? col.minWidth : undefined,
              align: col.align ? col.align : "left",
            },
            cell: (row) => {
              return formatDate(row.getValue());
            },
          });
          break;
        case columnTypes.HOUR:
          columnHelper.push({
            accessorKey: col.value,
            header: col.renderHeader ? col.renderHeader : col.name ? col.name : " ",
            enableSorting: col.sortable,
            meta: {
              mobile: col.mobile,
              minWidth: col.minWidth ? col.minWidth : undefined,
              align: col.align ? col.align : "left",
            },
            cell: (row) => {
              return formatMinutesToHours(row.getValue() as number);
            },
          });
          break;
        case columnTypes.CUSTOM:
          columnHelper.push({
            accessorKey: col.value,
            header: col.renderHeader ? col.renderHeader : col.name ? col.name : " ",
            enableSorting: false,
            meta: {
              mobile: col.mobile,
              minWidth: col.minWidth ? col.minWidth : undefined,
              align: col.align ? col.align : "left",
            },
            cell: (row) => {
              return col.renderCell && col.renderCell(row);
            },
          });
          break;
        default:
          columnHelper.push({
            accessorKey: col.value,
            header: col.renderHeader ? col.renderHeader : col.name ? col.name : " ",
            enableSorting: col.sortable,
            meta: {
              mobile: col.mobile,
              minWidth: col.minWidth ? col.minWidth : undefined,
              align: col.align ? col.align : "left",
            },
            cell: (row) => row.getValue(),
          });
      }
    });

    return columnHelper;
  };

  return { dataTableColumns: normalizeColumns(columns) };
}
