import { useEffect, useState } from "react";
import Button from "../Button/Button";
import Calendar from "../Calendar/Calendar";
import InputAutocomplete from "../InputAutocomplete/InputAutocomplete";
import { IAutocompleteFilterProps, IButtonProps, ICalendarProps, IOption } from "../../constants/interfaces";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import "./styles.scss";

interface IFiltersProps {
  autocompleteFilterProps?: IAutocompleteFilterProps;
  calendarProps?: ICalendarProps;
  buttonsProps?: IButtonProps[];
  downloadCSVButtonProps?: IButtonProps;
  csvDataForDownload?: any[];
  onDownloadCSV?: () => void;
  idCalendar?: string;
}

const Filters = observer((props: IFiltersProps) => {
  const { autocompleteFilterProps, calendarProps, buttonsProps, downloadCSVButtonProps, onDownloadCSV } = props;
  const defaultButtonSxStyle = {
    padding: "0.492rem 2.1rem",
    textTransform: "capitalize",
    fontSize: "1.5rem",
    borderRadius: ".8rem",
    "& .MuiButton-icon": {
      "& > *:nth-of-type(1)": {
        fontSize: "1.5rem",
      },
    },
  };

  useEffect(() => {
    return () => {
      //Calendar clear
      setStartDateError("");
      setEndDateError("");
      setStartDate(null);
      setEndDate(null);
    };
  }, []);

  //#region Autocomplete logic
  const handleAutocompleteMultiChange = (event: React.ChangeEvent<{}>, value: IOption[] | IOption | null) => {
    const onChange = autocompleteFilterProps?.OnChangeMulti;
    if (onChange) {
      onChange(value);
    }
  };

  const handleAutocompleteSingleChange = (event: React.ChangeEvent<{}>, value: IOption[] | IOption | null) => {
    const onChange = autocompleteFilterProps?.onChange;
    if (onChange) {
      onChange(value);
    }
  };
  //#endregion Autocomplete

  //#region Calendar logic
  const [startDate, setStartDate] = useState<DateTime | null>(null);
  const [endDate, setEndDate] = useState<DateTime | null>(null);
  const [startDateError, setStartDateError] = useState<string>("");
  const [endDateError, setEndDateError] = useState<string>("");

  useEffect(() => {
    calendarProps?.startDate && setStartDate(calendarProps?.startDate);
    calendarProps?.endDate && setEndDate(calendarProps?.endDate);

    return () => {
      //Calendar clear
      setStartDateError("");
      setEndDateError("");
      setStartDate(null);
      setEndDate(null);
    };
  }, [calendarProps?.withCalendarRange]);

  const handleDateFromChange = (newValue: DateTime | null, error: any) => {
    if (error?.validationError) setStartDateError("Invalid date");
    else {
      setStartDate(newValue);
      setStartDateError("");
    }
  };

  const handleDateToChange = (newValue: DateTime | null, error: any) => {
    if (error?.validationError) setEndDateError("Invalid date");
    else {
      setEndDate(newValue);
      setEndDateError("");
    }
  };

  const dateValidator = () => {
    if (startDate === null && endDate === null) {
      setEndDateError("");
      const customOnChange = calendarProps?.customOnChange;
      if (customOnChange) {
        customOnChange(undefined, undefined);
      }
    }
    if (startDate !== null && endDate !== null) {
      if (endDate.startOf("day") < startDate.startOf("day")) {
        setEndDateError("Date must be greater than start date");
        setEndDate(null);
      } else {
        setEndDateError("");
        const customOnChange = calendarProps?.customOnChange;
        if (customOnChange) {
          const startDateAux = startDate.toISODate()?.toString() ?? undefined;
          const endDateAux = endDate.toISODate()?.toString() ?? undefined;
          customOnChange(startDateAux, endDateAux);
        }
      }
    }
  };

  useEffect(() => {
    dateValidator();
  }, [startDate, endDate]);

  //#endregion Calendar logic

  return (
    <div className="filters">
      {autocompleteFilterProps?.withInputAutocompleteSingle && autocompleteFilterProps?.optionsSingle && (
        <div className="filter-item">
          <InputAutocomplete
            options={autocompleteFilterProps.optionsSingle}
            label={autocompleteFilterProps.labelSingle}
            value={autocompleteFilterProps.singleValue}
            placeholder={autocompleteFilterProps.placeholderSingle}
            icon={autocompleteFilterProps.iconSingle}
            iconPosition={autocompleteFilterProps.iconPositionSingle}
            sx={autocompleteFilterProps.sxSingle}
            multiple={false}
            onChange={handleAutocompleteSingleChange}
            id={autocompleteFilterProps.idSingle}
          />
        </div>
      )}
      {autocompleteFilterProps?.withInputAutocompleteMulti && autocompleteFilterProps?.optionsMulti && (
        <div className="filter-item">
          <InputAutocomplete
            options={autocompleteFilterProps.optionsMulti}
            label={autocompleteFilterProps.labelMulti}
            value={autocompleteFilterProps.multiValue}
            placeholder={autocompleteFilterProps.placeholderMulti}
            icon={autocompleteFilterProps.iconMulti}
            iconPosition={autocompleteFilterProps.iconPositionMulti}
            sx={autocompleteFilterProps.sxMulti}
            multiple={true}
            onChange={handleAutocompleteMultiChange}
            id={autocompleteFilterProps.idMulti}
          />
        </div>
      )}
      {calendarProps?.withCalendarRange && (
        <div className="filter-item filter-calendar-range filters-calendar-separator" id={props.idCalendar}>
          <Calendar
            value={calendarProps.startDate}
            maxDate={calendarProps.allowFuture ? undefined : DateTime.now()}
            label="Start date"
            sx={calendarProps.sx}
            error={startDateError}
            onChange={handleDateFromChange}
          />
          <hr />
          <Calendar
            value={calendarProps.endDate}
            maxDate={calendarProps.allowFuture ? undefined : DateTime.now()}
            label="End date"
            sx={calendarProps.sx}
            error={endDateError}
            onChange={handleDateToChange}
          />
        </div>
      )}
      {buttonsProps?.map((button) => (
        <div className="filter-item-btn">
          <Button
            id={button.id}
            onClick={button.onClick}
            text={button.text}
            sx={defaultButtonSxStyle}
            startIcon={button.startIcon}
            disabled={button.disabled}
            variant={button.variant}
            size={button.size}
          />
        </div>
      ))}

      {downloadCSVButtonProps?.show && (
        <div className="filter-item-btn filter-CSV-btn">
          <Button
            id={downloadCSVButtonProps.id}
            onClick={onDownloadCSV}
            text={downloadCSVButtonProps.text}
            sx={defaultButtonSxStyle}
            startIcon={downloadCSVButtonProps.startIcon}
            disabled={downloadCSVButtonProps.disabled}
            variant={downloadCSVButtonProps.variant}
            size={downloadCSVButtonProps.size}
          />
        </div>
      )}
    </div>
  );
});

export default Filters;
