import { Button as MUIButton } from "@mui/material";
import { IButtonProps } from "../../constants/interfaces";

const Button = (props: IButtonProps) => {
  const { text } = props;

  return <MUIButton {...props}>{text}</MUIButton>;
};

export default Button;
