export const PAGINATION = {
  page: 1,
  size: 10,
};

export const TYPE = {
  UNKNOWN: "Unknown",
  PLAN_50: "Plan50",
  PLAN_45: "Plan45",
  PLAN_40: "Plan40",
  CUSTOM: "Custom",
};

export const TYPE_LABELS = {
  [TYPE.UNKNOWN]: "Unknown",
  [TYPE.PLAN_50]: "50/20/30",
  [TYPE.PLAN_45]: "45/25/30",
  [TYPE.PLAN_40]: "40/30/30",
  [TYPE.CUSTOM]: "Custom plan",
};

export const LANGUAGE_LABELS: { [key: string]: string } = {
  "en-US": "English",
  "es-ES": "Spanish",
  Unknown: "-",
};
