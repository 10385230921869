import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants/routePaths";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import img from "../../assets/svg/bg-image.svg";
import IFWIcon from "../../assets/svg/IFWIcon";
import FWLogoWhite from "../../assets/images/fw-logo-white.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Spinner from "../../components/Spinner/Spinner";
import { useStore } from "../../store/StoreContext";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

enum ForgotPasswordStep {
  EMAIL,
  VERIFICATION,
  NEW_PASSWORD,
  CONFIRMATION,
}

interface FormValues {
  email: string;
  verificationCode: number | null;
  newPassword: string;
  confirmPassword: string;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
}

interface FormValues {
  email: string;
  verificationCode: number | null;
  newPassword: string;
  confirmPassword: string;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
}

const ForgotPass = () => {
  const navigate = useNavigate();
  const { authStore } = useStore();
  const [currentStep, setCurrentStep] = useState<ForgotPasswordStep>(ForgotPasswordStep.EMAIL);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    email: "",
    verificationCode: null,
    newPassword: "",
    confirmPassword: "",
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const [errors, setErrors] = useState({
    email: "",
    verificationCode: "",
    password: "",
  });

  const handleChange = (prop: keyof typeof formValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({ email: "", verificationCode: "", password: "" });
    setFormValues({ ...formValues, [prop]: event.target.value });
  };

  const signIn = () => {
    return navigate(ROUTE_PATHS.SIGN_IN);
  };

  const handleTogglePasswordVisibility = (field: "showNewPassword" | "showConfirmPassword") => {
    setFormValues({ ...formValues, [field]: !formValues[field] });
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formValues.email)) {
      setErrors((prev) => ({ ...prev, email: "Please enter a valid email address." }));
      return false;
    }
    return true;
  };

  const validateVerificationCode = () => {
    if (formValues.verificationCode === null) {
      setErrors((prev) => ({ ...prev, verificationCode: "Please enter a 6-digit code" }));
      return false;
    }
    const codeString = formValues.verificationCode.toString();
    const isNumeric = /^\d+$/.test(codeString);

    if (!isNumeric || codeString.length !== 6) {
      setErrors((prev) => ({ ...prev, verificationCode: "Please enter a 6-digit code" }));
      return false;
    }
    return true;
  };

  const validatePasswords = () => {
    if (formValues.newPassword !== formValues.confirmPassword) {
      setErrors((prev) => ({ ...prev, password: "Passwords do not match" }));
      return false;
    }

    const passwordValidation = true; // add more validations if needed. Example: formValues.newPassword.length < 8;

    if (!passwordValidation) {
      setErrors((prev) => ({ ...prev, password: "Password must be at least 8 characters long" }));
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    setErrors({ email: "", verificationCode: "", password: "" });

    switch (currentStep) {
      case ForgotPasswordStep.EMAIL:
        if (formValues.email && validateEmail()) {
          try {
            setLoading(true);
            await authStore.forgotPassword(formValues.email);
            setCurrentStep(ForgotPasswordStep.VERIFICATION);
          } catch (error: any) {
            //we don't need to show the error message here.
            setCurrentStep(ForgotPasswordStep.VERIFICATION);
          } finally {
            setLoading(false);
          }
        }
        break;

      case ForgotPasswordStep.VERIFICATION:
        if (formValues.verificationCode && validateVerificationCode()) {
          try {
            setLoading(true);
            await authStore.validateCode(formValues.verificationCode);
            setCurrentStep(ForgotPasswordStep.NEW_PASSWORD);
          } catch (error: any) {
            const message = error.message.replace("UnauthorizedError: ", "");
            setErrors((prev) => ({ ...prev, verificationCode: message }));
          } finally {
            setLoading(false);
          }
        }
        break;

      case ForgotPasswordStep.NEW_PASSWORD:
        if (validatePasswords()) {
          try {
            setLoading(true);
            const res = await authStore.passwordReset(formValues.newPassword);
            if (res) setCurrentStep(ForgotPasswordStep.CONFIRMATION);
          } catch (error: any) {
            const message = error.message.replace("UnauthorizedError: ", "");
            setErrors((prev) => ({ ...prev, password: message }));
          } finally {
            setLoading(false);
          }
        }
        break;

      case ForgotPasswordStep.CONFIRMATION:
        signIn();

        break;
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <Typography variant="body1" sx={{ textAlign: "start", marginBottom: "10px" }}>
            <Spinner size={50} />
          </Typography>
        </Box>
      );
    }

    switch (currentStep) {
      case ForgotPasswordStep.EMAIL:
        return (
          <>
            <Typography variant="body1" sx={{ textAlign: "start", marginBottom: "10px" }}>
              Please enter your account email to reset your password.
            </Typography>

            <FormControl variant="outlined" fullWidth margin="normal" error={!!errors.email}>
              <InputLabel htmlFor="forgot-input-email">Email</InputLabel>
              <OutlinedInput
                id="forgot-input-email"
                label="Email"
                placeholder="Email"
                value={formValues.email}
                onChange={handleChange("email")}
                endAdornment={
                  <InputAdornment position="end">
                    {errors.email ? (
                      <ErrorOutlineIcon color="error" fontSize="large" id="sign-in-icon-error-email" />
                    ) : (
                      formValues.email &&
                      validateEmail() && (
                        <CheckCircleOutlineIcon fontSize="large" color="primary" id="sign-in-icon-validator-email" />
                      )
                    )}
                  </InputAdornment>
                }
              />

              {errors.email && (
                <Typography color="error" variant="caption" fontSize="1.3rem" margin=".6rem 0">
                  {errors.email}
                </Typography>
              )}
            </FormControl>
          </>
        );

      case ForgotPasswordStep.VERIFICATION:
        return (
          <>
            <Typography variant="body1" sx={{ textAlign: "start", marginBottom: "10px" }}>
              If an account exists with this email, a reset code has been sent. When you receive an email enter the 6
              digit code here:
            </Typography>

            <FormControl variant="outlined" fullWidth margin="normal" error={!!errors.verificationCode}>
              <InputLabel htmlFor="verification-code">Code</InputLabel>
              <OutlinedInput
                id="verification-code"
                label="Code"
                placeholder="Code"
                value={formValues.verificationCode ?? ""}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, "").slice(0, 6);
                  setFormValues({
                    ...formValues,
                    verificationCode: numericValue ? parseInt(numericValue) : null,
                  });
                }}
                inputProps={{
                  maxLength: 6,
                  pattern: "[0-9]*",
                  inputMode: "numeric",
                }}
              />

              {errors.verificationCode && (
                <Typography color="error" variant="caption" fontSize="1.3rem" margin=".6rem 0">
                  {errors.verificationCode}
                </Typography>
              )}
            </FormControl>
          </>
        );

      case ForgotPasswordStep.NEW_PASSWORD:
        return (
          <>
            <Typography variant="body1" sx={{ textAlign: "start", marginBottom: "10px" }}>
              Enter your new password.
            </Typography>

            <FormControl variant="outlined" fullWidth margin="normal" error={!!errors.password}>
              <InputLabel htmlFor="new-password">New Password</InputLabel>
              <OutlinedInput
                id="new-password"
                type={formValues.showNewPassword ? "text" : "password"}
                label="New Password"
                placeholder="New Password"
                value={formValues.newPassword}
                onChange={handleChange("newPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleTogglePasswordVisibility("showNewPassword")} edge="end">
                      {formValues.showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth margin="normal" error={!!errors.password}>
              <InputLabel htmlFor="confirm-password">Repeat New Password</InputLabel>
              <OutlinedInput
                id="confirm-password"
                type={formValues.showConfirmPassword ? "text" : "password"}
                label="Repeat New Password"
                placeholder="New Password"
                value={formValues.confirmPassword}
                onChange={handleChange("confirmPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleTogglePasswordVisibility("showConfirmPassword")} edge="end">
                      {formValues.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />

              {errors.password && (
                <Typography color="error" variant="caption" fontSize="1.3rem" margin=".6rem 0">
                  {errors.password}
                </Typography>
              )}
            </FormControl>
          </>
        );

      case ForgotPasswordStep.CONFIRMATION:
        return (
          <>
            <Typography variant="body1" sx={{ textAlign: "start", marginBottom: "10px" }}>
              Password changed successfully. <br /> Please continue to Sign In.
            </Typography>
          </>
        );
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      sx={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <img src={FWLogoWhite} style={{ marginBottom: "5rem", width: "20rem" }} />
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          width: "36.8rem",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingX: "30px",
          paddingY: "40px",
          boxShadow: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: "16px",
            width: "100%",
          }}
        >
          <IFWIcon width="48px" height="48px" />
          <Typography variant="h3" sx={{ marginLeft: "18px" }}>
            CoachConnect
          </Typography>
        </Box>

        {renderContent()}

        {!loading && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ borderRadius: "8px", marginTop: "20px", width: "100%" }}
            onClick={handleSubmit}
            disabled={loading}
          >
            <Typography sx={{ textTransform: "capitalize", fontSize: "1.5rem" }}>
              {currentStep === ForgotPasswordStep.CONFIRMATION ? "Sign In" : "Submit"}
            </Typography>
          </Button>
        )}

        {(currentStep === ForgotPasswordStep.EMAIL || currentStep === ForgotPasswordStep.VERIFICATION) && !loading && (
          <Link textAlign="center" onClick={signIn} sx={{ cursor: "pointer", display: "block", marginTop: "3rem" }}>
            Back to Sign In
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default ForgotPass;
