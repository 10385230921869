import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { useState } from "react";
import { ICalendarProps } from "../../constants/interfaces";

const Calendar = (props: ICalendarProps) => {
  const { onChange, label = "Select date", value, error, sx } = props;
  const [userValue, setUserValue] = useState<DateTime | null>(value ?? null);

  const handleChange = (newValue: DateTime | null, error: {}) => {
    setUserValue(newValue);
    if (onChange) onChange(newValue, error);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DatePicker
        {...props}
        label={label}
        value={userValue}
        onChange={handleChange}
        sx={{
          "& .MuiInputBase-input": {
            padding: "8.5px 14px",
          },
          ...sx,
        }}
        slotProps={{
          textField: {
            helperText: error,
            error: !!error,
            size: "small",
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default Calendar;
