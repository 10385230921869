export const ROUTE_PATHS = {
  SIGN_IN: "/sign-in",
  SIGN_OUT: "/sign-out",
  FORGOT_PASS: "/forgot-password",
  HOME: "/home",
  TABLE: "/table",
  MY_LEADS: "/my-leads",
  MY_CLIENTS: "/my-clients",
  MY_CLIENTS_TABS: "/my-clients-tabs/:id",
  CLIENT_REPORTS: "/my-clients/client-reports",
  ENERGY_CHART: "/energy-chart",
  PAP: "/pap",
  TRAINING_PORTAL: "/training-portal",
  HELP: "/help",
  PAGE_NOT_FOUND: "/pagenotfound",
  UPCOMING_BIRTHDAYS: "/my-clients/upcoming-birthdays",
  CANCELLED_CLIENTS: "/my-clients/cancelled-clients",
  STAR_REPORT: "/my-clients/star-report",
};
