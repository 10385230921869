import "./styles.scss";

import Card, { CardColor } from "../../../components/Card/Card";
import { ROUTE_PATHS } from "../../../constants/routePaths";
import MainCard from "../../../components/MainCard/MainCard";
import { useNavigate } from "react-router-dom";

interface CardProps {
  title: string;
  secondaryText: string;
  path: string;
  color: CardColor;
  id: string;
  customClick?: boolean;
  customColor?: string;
  backgroundColor?: string;
  iconColor?: string;
}

const cardsData: CardProps[] = [
  {
    title: "Cancelled Clients",
    secondaryText: "Reach out to cancelled Clients to come back.",
    path: ROUTE_PATHS.CANCELLED_CLIENTS,
    color: "secondary",
    id: "card-cancelled-clients",
    customClick: true,
    customColor: "#0288D1",
    backgroundColor: "#F5F5F5",
    iconColor: "#0288D1",
  },
  {
    title: "Upcoming Birthdays",
    secondaryText: "Send a fun birthday note to your clients!",
    path: ROUTE_PATHS.UPCOMING_BIRTHDAYS,
    color: "secondary",
    id: "card-upcoming-birthdays",
    customColor: "#0288D1",
    backgroundColor: "#F5F5F5",
    iconColor: "#0288D1",
  },
  {
    title: "Star Report",
    secondaryText: "Monitor Client Stars between dates.",
    path: ROUTE_PATHS.STAR_REPORT,
    color: "secondary",
    id: "card-start-report",
    customColor: "#0288D1",
    backgroundColor: "#F5F5F5",
    iconColor: "#0288D1",
  },
];

const ClientReports = () => {
  const navigate = useNavigate();

  const handleClickCard = (path: string) => {
    navigate(path);
  };

  return (
    <MainCard>
      <div className="client-report-description">
        <p>Use these reports to connect with clients, track activity, and grow your business.</p>
      </div>
      <div className="client-report">
        {cardsData.map((card, index) => (
          <Card
            className="card"
            id={card.id}
            key={index}
            title={card.title}
            secondaryText={card.secondaryText}
            onClick={() => handleClickCard(card.path)}
            color={card.color}
            customClick={card.customClick}
            customColor={card.customColor}
            backgroundColor={card.backgroundColor}
            iconColor={card.iconColor}
          />
        ))}
      </div>
    </MainCard>
  );
};

export default ClientReports;
