import Papa from "papaparse";

export const downloadCSV = (data: any[], filename: string = "CoachConnectCSV.csv") => {
  if (data.length === 0) {
    throw new Error("There is no data to download");
  }
  try {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    throw new Error("Something went wrong while downloading the file");
  }
};
