import { lazy } from "react";
import { ROUTE_PATHS } from "../constants/routePaths";

import SignIn from "../pages/SignIn/SignIn";
import EnergyChart from "../pages/EnergyChart/EnergyChart";
import PAP from "../pages/PAP/PAP";
import TrainingPortal from "../pages/TrainingPortal/TrainingPortal";
import Help from "../pages/Help/Help";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import ForgotPass from "../pages/ForgotPass/ForgotPass";
import Home from "../pages/Home/Home";
import ClientReports from "../pages/MyClients/ClientReports/ClientReports";
import UpcomingBirthdays from "../pages/MyReports/UpcomingBirthdays";
import CancelledClients from "../pages/MyReports/CancelledClients";
import StarReport from "../pages/MyReports/StarReport";
const MyClientsTabs = lazy(() => import("../pages/MyClients/Tabs/MyClientsTabs"));
const MyClients = lazy(() => import("../pages/MyClients/MyClients"));
const MyLeads = lazy(() => import("../pages/MyLeads/MyLeads"));
const Table = lazy(() => import("../pages/Table/Table"));

export type routeProps = { exact?: boolean; path: string; private?: boolean; component: any; nameKey?: string };

export const routeArray: routeProps[] = [
  {
    path: "/",
    component: <SignIn />,
  },
  {
    path: ROUTE_PATHS.SIGN_IN,
    component: <SignIn />,
  },
  {
    path: ROUTE_PATHS.FORGOT_PASS,
    component: <ForgotPass />,
  },
  {
    path: ROUTE_PATHS.HOME,
    component: <Home />,
    private: true,
  },
  {
    path: ROUTE_PATHS.MY_LEADS,
    component: <MyLeads />,
    private: true,
  },
  {
    path: ROUTE_PATHS.TABLE,
    component: <Table />,
    private: true,
  },
  {
    path: ROUTE_PATHS.MY_CLIENTS,
    component: <MyClients/>,
    private: true,
  },
  {
    path: ROUTE_PATHS.CLIENT_REPORTS,
    component: <ClientReports/>,
    private: true,
  },  
  {
    path: ROUTE_PATHS.CANCELLED_CLIENTS,
    component: <CancelledClients />,
    private: true,
  },
  {
    path: ROUTE_PATHS.MY_CLIENTS_TABS,
    component: <MyClientsTabs />,
    private: true,
  },
  {
    path: ROUTE_PATHS.UPCOMING_BIRTHDAYS,
    component: <UpcomingBirthdays />,
    private: true,
  },
  {
    path: ROUTE_PATHS.STAR_REPORT,
    component: <StarReport />,
    private: true,
  },
  {
    path: ROUTE_PATHS.ENERGY_CHART,
    component: <EnergyChart />,
    private: true,
  },
  {
    path: ROUTE_PATHS.PAP,
    component: <PAP />,
    private: true,
  },
  {
    path: ROUTE_PATHS.TRAINING_PORTAL,
    component: <TrainingPortal />,
    private: true,
  },
  {
    path: ROUTE_PATHS.HELP,
    component: <Help />,
    private: true,
  },
  {
    path: "*",
    component: <PageNotFound />,
    nameKey: "Page-not-found",
    private: true,
  },
];
