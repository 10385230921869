import {
  ColumnSort,
  flexRender,
  getCoreRowModel,
  HeaderContext,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Column, TableOptions } from "./datatable.models";
import { useNormalizeColumns } from "./datatable.normalize.columns";
import "./styles.scss";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ArrowBackIos, ArrowDownward, ArrowForwardIos, ArrowUpward } from "@mui/icons-material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { PAGINATION } from "../../constants/const";
import IconButton from "@mui/material/IconButton";
import Spinner from "../Spinner/Spinner";
/**
 * columns: Column object with the settings for each table column
 * data: object[] with the API response
 * totalRows: total count of the data set returned by the endpoint in order to defined the pagination
 * orderBy: it enables a default sorting when render the table. It is an string with the column 'value' to be passed in the API call in order to get the data sorted in ascending mode by specific column
 * orderByDesc: it enables a default sorting when render the table. It is an string with the column 'value' to be passed in the API call in order to get the data sorted in descending mode by specific column
 * mobileBreakpoint: it enables the responsive table behavior. It is a number to identify when the screen size is smaller than, so the table will hide the columns with prop 'mobile' in false and will enable the expandable rows if prop 'renderExpandableComponent' exists
 * expandRowOnMobileBreakpoint: it enables the expandable rows behavior. Renders a common component  when a row is expanded. If this prop is present without 'mobileBreakpoint' will not take effect.
 * renderExpandableComponent: it enables the expandable rows behavior. It is the component to be render when a row is expanded. If this prop is present without 'mobileBreakpoint' propd, the rows will be always expandable
 * handleRowClicked: it enables the row click behavior. It is the function to be call when clicking on the entire row (excluding the expandable first row is it is enabled)
 * handleTableOptionsChanged: function automatically called every time that the sorting or the pagination changed.
 */
export type DataTableProps = {
  columns: Column[];
  data: object[];
  totalRows: number;
  loading: boolean;
  paginationValue?: { page: number; size: number };
  orderBy?: string;
  orderByDesc?: string;
  mobileBreakpoint?: number;
  higlightedRow?: number;
  expandRowOnMobileBreakpoint?: boolean;
  getRowCanExpand?: (row: object) => boolean;
  renderExpandableComponent?: (row: object) => JSX.Element;
  handleRowClicked?: (row: object) => void;
  handleTableOptionsChanged: (params: TableOptions) => void;
};

const DataTable = ({
  columns,
  data,
  loading = false,
  totalRows,
  paginationValue,
  orderBy,
  orderByDesc,
  mobileBreakpoint,
  higlightedRow,
  expandRowOnMobileBreakpoint = false,
  getRowCanExpand,
  renderExpandableComponent,
  handleRowClicked,
  handleTableOptionsChanged,
}: DataTableProps) => {
  const { dataTableColumns } = useNormalizeColumns(
    columns,
    !!renderExpandableComponent || (!!mobileBreakpoint && expandRowOnMobileBreakpoint)
  );
  const [columnVisibility, setColumnVisibility] = useState({});
  const defaultSorting: ColumnSort[] =
    orderBy || orderByDesc
      ? [{ desc: orderByDesc ? true : false, id: (orderBy ? orderBy : orderByDesc) as string }]
      : [];
  const [sorting, setSorting] = useState<SortingState>(defaultSorting);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: paginationValue ? paginationValue.page : 0,
    pageSize: paginationValue ? paginationValue.size : PAGINATION.size,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  /**
   * Can be always expandable or only when the defined breakpoint is achived.
   */
  const handleRowCanExpand = (row: Row<object>): boolean => {
    if (!!renderExpandableComponent || expandRowOnMobileBreakpoint) {
      if (mobileBreakpoint) {
        return window.innerWidth < mobileBreakpoint && (getRowCanExpand ? getRowCanExpand(row.original) : true);
      } else {
        return getRowCanExpand ? getRowCanExpand(row.original) : true;
      }
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  /**
   * Change column visibility when the defined breakpoint is achived.
   */
  const handleResize = useCallback(() => {
    if (mobileBreakpoint && window.innerWidth < mobileBreakpoint) {
      const tableColumns = table.getVisibleFlatColumns();
      // eslint-disable-next-line array-callback-return
      tableColumns.map((col) => {
        col.columnDef.meta &&
          (col.columnDef.meta as Record<string, boolean>).mobile === false &&
          col.toggleVisibility(false); //hide the column
      });
    } else {
      table.resetColumnVisibility(); //show all columns again.
      table.toggleAllRowsExpanded(false); //close all expanded rows when screen is small
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileBreakpoint]);

  /**
   * funtion to be called on each row expand action.
   * We get the entire row object and need to filter by not mobile columns to be shown in the collapsable section.
   */
  const renderExpandedRow = (row: Row<object>) => {
    return (
      <div className="expandable-content">
        {
          // eslint-disable-next-line array-callback-return
          row.getAllCells().map((cell, index) => {
            if (!(cell.column.columnDef.meta as Record<string, string>).mobile) {
              return (
                <div className="content" id={row.id + index} key={row.id + index}>
                  <b>
                    {flexRender(
                      cell.column.columnDef.header,
                      table.getFlatHeaders().at(index)?.getContext() as HeaderContext<object, unknown>
                    )}
                    :
                  </b>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              );
            }
          })
        }
      </div>
    );
  };

  useEffect(() => {
    table.toggleAllRowsExpanded(false); //close all expanded rows
    const params: TableOptions = {
      sortBy: sorting[0] && !sorting[0].desc ? sorting[0].id : undefined,
      sortByDesc: sorting[0] && sorting[0].desc ? sorting[0].id : undefined,
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
    };
    handleTableOptionsChanged(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting, pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, [handleResize]);

  const table = useReactTable({
    data,
    columns: dataTableColumns,
    pageCount: Math.ceil(totalRows / pagination.pageSize),
    state: {
      sorting,
      pagination,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getRowCanExpand: handleRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    manualSorting: true,
    manualPagination: true,
    autoResetPageIndex: totalRows < pagination.pageSize * pagination.pageIndex,
  });

  return (
    <div className="table-container">
      {loading && <Spinner size={50} sx={{ position: "absolute", top: "45%", left: "55%" }} />}
      <table className={handleRowClicked ? "data-table clickeable" : "data-table"}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ padding: header.id === "expander" ? "0px" : "auto" }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        style={{
                          justifyContent: (header.column.columnDef.meta as Record<string, string>).align,
                        }}
                        className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === "asc"
                              ? "Sort ascending"
                              : header.column.getNextSortingOrder() === "desc"
                              ? "Sort descending"
                              : "Clear sort"
                            : undefined
                        }
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getCanSort() &&
                          ({
                            asc: <ArrowUpward fontSize="small" />,
                            desc: <ArrowDownward fontSize="small" />,
                          }[header.column.getIsSorted() as string] ?? (
                            <ArrowUpward id="sortingIcon" fontSize="small" color="disabled" />
                          ))}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row, index) => (
              <Fragment key={row.id}>
                <tr
                  className={
                    higlightedRow === index
                      ? "selected"
                      : table.getCanSomeRowsExpand()
                      ? row.getIsExpanded()
                        ? "expanded"
                        : "expand"
                      : ""
                  }
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      style={
                        cell.column.columnDef.id === "expander"
                          ? table.getCanSomeRowsExpand()
                            ? { width: "3rem" }
                            : { width: "0", padding: "0" }
                          : cell.column.columnDef.meta &&
                            (cell.column.columnDef.meta as Record<string, boolean>).minWidth
                          ? { minWidth: (cell.column.columnDef.meta as Record<string, boolean>).minWidth + "rem" }
                          : {}
                      }
                      align={
                        (cell.column.columnDef.meta as Record<string, string>).align as "right" | "left" | "center"
                      }
                      key={cell.id}
                      onClick={() => {
                        cell.column.columnDef.id !== "expander" && handleRowClicked && handleRowClicked(row.original);
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
                {renderExpandableComponent && row.getIsExpanded() && (
                  <tr className="expandable-row">
                    <td style={{ padding: "0" }} colSpan={row.getVisibleCells().length}>
                      {renderExpandableComponent(row.original)}
                    </td>
                  </tr>
                )}
                {!renderExpandableComponent &&
                  mobileBreakpoint &&
                  expandRowOnMobileBreakpoint &&
                  row.getIsExpanded() && (
                    <tr className="expandable-row">
                      <td style={{ padding: "0" }} colSpan={row.getVisibleCells().length}>
                        {renderExpandedRow(row)}
                      </td>
                    </tr>
                  )}
              </Fragment>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="no-rows">
                {!loading ? "No Rows" : ""}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {totalRows > 0 && (
        <div className="pagination">
          <div>
            <span className="per-page">Rows per page:</span>
            <Select
              id="perPage"
              value={table.getState().pagination.pageSize}
              size="small"
              className="select"
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </div>
          <div>
            <span>
              {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}-
              {table.getState().pagination.pageIndex + 1 === table.getPageCount()
                ? totalRows
                : (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize}{" "}
            </span>
            <span className="small-regular">of</span> <span className="small-bold">{totalRows}</span>
          </div>
          <div>
            <IconButton id="prevPage" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
              <ArrowBackIos fontSize="small" />
            </IconButton>
            <IconButton id="nextPage" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
              <ArrowForwardIos fontSize="small" />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataTable;
