import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "@mui/material";
import DataTable, { DataTableProps } from "../DataTable/DataTable";
import Filters from "../../components/Filters/Filters";
import Snackbar from "../Snackbar/Snackbar";
import { downloadCSV } from "../../utils/CSVHandler";
import {
  IAutocompleteFilterProps,
  IButtonProps,
  ICalendarProps,
  IMyLeadsCSVData,
  IClientCSVData,
} from "../../constants/interfaces";

import "./styles.scss";

interface IProps {
  tableProps: DataTableProps;
  autocompleteFilterProps?: IAutocompleteFilterProps;
  calendarProps?: ICalendarProps;
  buttonsProps?: IButtonProps[];
  downloadCSVButtonProps?: IButtonProps;
  idTable: string;
  idCalendar?: string;
  getCSVData?: () => Promise<IMyLeadsCSVData[] | IClientCSVData[]>;
  header?: React.ReactNode;
}

const Table = observer((props: IProps) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    tableProps,
    autocompleteFilterProps,
    calendarProps,
    buttonsProps,
    downloadCSVButtonProps,
    getCSVData,
    header,
  } = props;

  const {
    columns,
    data,
    totalRows,
    loading,
    orderBy,
    orderByDesc,
    paginationValue,
    mobileBreakpoint,
    higlightedRow,
    expandRowOnMobileBreakpoint,
    getRowCanExpand,
    renderExpandableComponent,
    handleRowClicked,
    handleTableOptionsChanged,
  } = tableProps;

  const handleDownloadCSV = async () => {
    try {
      if (getCSVData) {
        const data: IMyLeadsCSVData[] | IClientCSVData[] = await getCSVData();
        if (data?.length > 0) {
          downloadCSV(data);
        } else {
          throw new Error("No data available for download");
        }
      } else {
        throw new Error("An error occurred while downloading CSV");
      }
    } catch (error: any) {
      setErrorMessage(error?.message);
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <Box sx={{ width: "100%", height: "100%", p: 1 }} id={props.idTable}>
      <Filters
        autocompleteFilterProps={autocompleteFilterProps}
        calendarProps={calendarProps}
        buttonsProps={buttonsProps}
        downloadCSVButtonProps={downloadCSVButtonProps}
        onDownloadCSV={handleDownloadCSV}
        idCalendar={props.idCalendar}
      />

      {header && <Box>{header}</Box>}

      <Box>
        <DataTable
          columns={columns}
          data={data}
          totalRows={totalRows ?? 0}
          loading={loading}
          paginationValue={paginationValue}
          orderBy={orderBy}
          orderByDesc={orderByDesc}
          mobileBreakpoint={mobileBreakpoint}
          higlightedRow={higlightedRow}
          expandRowOnMobileBreakpoint={expandRowOnMobileBreakpoint}
          getRowCanExpand={getRowCanExpand}
          renderExpandableComponent={renderExpandableComponent}
          handleTableOptionsChanged={handleTableOptionsChanged}
          handleRowClicked={handleRowClicked}
        ></DataTable>
      </Box>
      <Snackbar open={openSnackbar} onClose={handleCloseSnackbar} severity="error" message={errorMessage} />
    </Box>
  );
});

export default Table;
