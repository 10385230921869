import React from "react";
import { default as CardMUI } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import "./style.scss";
import { Box, Divider, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export type CardColor = "primary" | "secondary";

interface MyCardProps {
  title: string;
  primaryText?: string | number;
  secondPrimaryText?: string | number;
  secondaryText: string;
  secondSecondaryText?: string;
  onClick: (arg0: string) => void;
  color: CardColor;
  id: string;
  className: string;
  customClick?: boolean;
  icon?: OverridableComponent<SvgIconTypeMap>;
  customColor?: string;
  backgroundColor?: string;
  iconColor?: string;
}

const Card: React.FC<MyCardProps> = ({
  title,
  primaryText,
  secondPrimaryText,
  secondaryText,
  secondSecondaryText,
  onClick,
  color,
  id,
  customClick,
  icon,
  customColor,
  backgroundColor,
  iconColor,
}) => {
  const handleClientsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick("monthly");
  };

  const handleVipsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick("VIP");
  };

  const Icon = icon;

  return (
    <CardMUI
      className={`card ${color}`}
      id={id}
      onClick={() => secondSecondaryText === undefined && onClick("")}
      sx={{
        position: "relative",
        cursor: secondSecondaryText === undefined ? "pointer" : "default",
        minHeight: "136px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "433px",
        minWidth: "342px",
        backgroundColor: backgroundColor ? backgroundColor : "white",
      }}
    >
      <CardContent
        sx={{
          height: " -webkit-fill-available",
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          height={"100%"}
          justifyContent={"space-around"}
        >
          <Box
            display={"flex"}
            justifyContent={color === "secondary" ? "space-between" : "start"}
            flexDirection={"row"}
            width={"100%"}
          >
            {icon && color === "primary" && Icon && (
              <Icon
                sx={{
                  fontSize: "2.5rem",
                  color: "#5BC0BD",
                  marginRight: "20px",
                }}
              />
            )}
            <Typography variant="h6" component="div">
              {title}
            </Typography>
            {color === "secondary" && (
              <ArrowCircleRightOutlinedIcon
                sx={{
                  color: iconColor ? iconColor : "action",
                  fontSize: "2.4rem",
                }}
              />
            )}
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            justifyContent={"stretch"}
            sx={{ cursor: "pointer" }}
            height={"100%"}
          >
            {secondSecondaryText && (
              <Divider sx={{ mx: -2, paddingTop: "20px" }} />
            )}
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
              width={"100%"}
              height={"100%"}
            >
              <Box
                display={"flex"}
                justifyContent={"space-around"}
                flexDirection={"row"}
                width="100%"
                alignItems={"center"}
                onClick={
                  secondSecondaryText === undefined && !customClick
                    ? undefined
                    : handleClientsClick
                }
                sx={{ position: "relative", marginRight: 2 }}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  height={"100%"}
                  justifyContent={"stretch"}
                  width={"100%"}
                >
                  <Box
                    display={"flex"}
                    justifyContent={color === "secondary" ? "start" : "center"}
                    flexDirection={"row"}
                    width={"100%"}
                  >
                    {color === "primary" && (
                      <>
                        <Typography
                          variant="h2"
                          component="div"
                          sx={{
                            marginTop: 7,
                            marginBottom: 1,
                          }}
                        >
                          {primaryText}
                        </Typography>
                        <ArrowCircleRightOutlinedIcon
                          color="secondary"
                          sx={{
                            fontSize: "2.4rem",
                            position: "absolute",
                            right: {
                              xs: secondSecondaryText ? -8 : -16,
                              sm: 8,
                            },
                            top: "50%",
                            transform: "translateY(-50%)",
                          }} />
                      </>
                    )}
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={color === "secondary" ? "start" : "center"}
                    flexDirection={"row"}
                    width={"100%"}
                  >
                    {icon && color === "secondary" && Icon && (
                      <Icon
                        sx={{
                          fontSize: "3rem",
                          color: "#5BC0BD",
                          marginRight: "20px",
                          marginTop: "20px",
                        }}
                      />
                    )}
                    <Typography
                      marginTop={color === "secondary" ? "20px" : "0px"}
                      variant="body1"
                      color="text.secondary"
                      sx={{ marginBottom: 1 }}
                    >
                      {secondaryText}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {secondSecondaryText && (
                <>
                  <Divider orientation="vertical" sx={{ height: "160px" }} />
                  <Box
                    display={"flex"}
                    justifyContent={"space-around"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    width="100%"
                    onClick={
                      secondSecondaryText === undefined
                        ? undefined
                        : handleVipsClick
                    }
                    sx={{ position: "relative", marginLeft: 2 }}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      width={"100%"}
                      justifyContent={"stretch"}
                      sx={{ cursor: "pointer" }}
                      height={"100%"}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={color === "secondary" ? "start" : "center"}
                        flexDirection={"row"}
                        width={"100%"}
                      >
                        {color === "primary" && (
                          <>
                            <Typography
                              variant="h2"
                              component="div"
                              sx={{
                                marginTop: 7,
                                marginBottom: 1,
                              }}
                            >
                              {secondPrimaryText}
                            </Typography>
                            <ArrowCircleRightOutlinedIcon
                              color="secondary"
                              sx={{
                                fontSize: "2.4rem",
                                position: "absolute",
                                right: {
                                  xs: secondSecondaryText ? -6 : -14,
                                  sm: 8,
                                },
                                top: "50%",
                                transform: "translateY(-50%)",
                              }} 
                            />
                          </>
                        )}
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={color === "secondary" ? "start" : "center"}
                        flexDirection={"row"}
                        width={"100%"}
                      >
                        <Typography
                          marginTop={color === "secondary" ? "20px" : "0px"}
                          variant="body1"
                          color="text.secondary"
                          sx={{ marginBottom: 1 }}
                        >
                          {secondSecondaryText}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </CardContent>
      <div
        className={`card-color-line ${color}`}
        style={ customColor ? {backgroundColor: customColor } : {}}
      />
    </CardMUI>
  );
};

export default Card;
