import { SvgIconComponent } from "@mui/icons-material";
import { SxProps } from "@mui/material";
import { DateTime } from "luxon";
import { ButtonProps } from "@mui/material";

//Interface used to align icon postion for Autocomplete
export enum ENUMIconPosition {
  START = "start",
  END = "end",
}

//Interface used to order
export enum ENUMDataGridOrder {
  ASC = "asc",
  DESC = "desc",
}

//Interface used to set Autocomplete data
export interface IOption {
  id?: number;
  label: string;
  value: string;
  group?: string; // Propiedad opcional para la agrupación
}

//IAutocompleteProps -> this is used only if call a component InputAutocomplete without filter
//options: Array option to Autocomplete
//label: Label for the input in autocomplete
//placeholder:Placeholder for the input in autocomplete
//icon:Icon for the input in autocomplete
//iconPosition:Position for the icon, for this we have a enum: IIconPosition
//sx:Styles for the input autocomplete
//onChange:Function to handle de onchange in autocomplete
export interface IAutocompleteProps {
  options: IOption[];
  value?: IOption | IOption[];
  label?: string;
  placeholder?: string;
  multiple?: boolean;
  icon?: SvgIconComponent;
  iconPosition?: ENUMIconPosition;
  sx?: SxProps;
  onChange?: (event: React.ChangeEvent<{}>, value: IOption[] | IOption | null) => void;
  id?: string;
}

//IAutocompleteFilterProps -> this is used only for filter component
//Single= will be used for the Autocomplete that need only one option
//Multi= will be replace the chips component, you could select a few options
//withInputAutocompleteSingle= If is true and optionsSingle != [] | null you can render a autocomplete for a single option
//withInputAutocompleteMulti= If is true and optionsMulti != [] | null you can render a autocomplete for a few option
//optionsSingle & optionsMulti: Array option to Autocomplete
//labelSingle & labelMulti: Label for the input in autocomplete
//placeholderSingle & placeholderMulti:Placeholder for the input in autocomplete
//iconSingle & iconMulti:Icon for the input in autocomplete
//iconPositionSingle & iconPositionMulti:Position for the icon, for this we have a enum: IIconPosition
//sxSingle & sxMulti:Styles for the input autocomplete
//onChange:Function to handle de onchange in autocomplete, for the moment we have only one, the logic is in filter for the moment.
export interface IAutocompleteFilterProps {
  optionsSingle?: IOption[];
  optionsMulti?: IOption[];
  singleValue?: IOption;
  multiValue?: IOption[];
  labelSingle?: string;
  labelMulti?: string;
  placeholderSingle?: string;
  placeholderMulti?: string;
  withInputAutocompleteSingle?: boolean;
  withInputAutocompleteMulti?: boolean;
  iconSingle?: SvgIconComponent | any;
  iconMulti?: SvgIconComponent | any;
  iconPositionSingle?: ENUMIconPosition;
  iconPositionMulti?: ENUMIconPosition;
  sxSingle?: SxProps;
  sxMulti?: SxProps;
  onChange?: (value: IOption[] | IOption | null) => void;
  OnChangeMulti?: (value: IOption[] | IOption | null) => void;
  idSingle?: string;
  idMulti?: string;
}

//ICalendarProps
//withCalendarRange: Only used for Filters, this render two calendar with the logic to make a function Date from - Date to
//value: Calendar date, used DateTime from luxon
//label: Label for the calendar
//error: Message for shown a error, also change the style to make a border red in calendar
//sx: styles
//onChange: Function to handle the date when is changed
//customOnChange: Function to get startDate and endDate to filters
export interface ICalendarProps {
  withCalendarRange?: boolean;
  allowFuture?: boolean;
  minDate?: DateTime;
  maxDate?: DateTime;
  value?: DateTime;
  startDate?: DateTime;
  endDate?: DateTime;
  label?: string;
  error?: string;
  sx?: SxProps;
  onChange?: (newValue: DateTime | null, error: any) => void;
  customOnChange?: (startDate: string | undefined, endDate: string | undefined) => void;
}

//IButtonProps
//ButtonProps: MUI's props to button
//text: Text used in the button
export type IButtonProps = {
  id: string;
  show?: boolean;
  text: string;
} & ButtonProps;

export interface IRowMyLeads {
  id: number;
  date: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  source: string;
}

export interface IRowAppTracking {
  id: number;
  date: string;
  startsDay: number;
  workoutDay: boolean;
  waterDay: number;
  fastingDay: number;
  foodDay: number;
  walkDay: number;
}
//TODO: Unificar los param en una sola interface
export interface IParam {
  endDate?: string;
  startDate?: string;
  lastName?: string;
  orderBy?: string;
  orderByDesc?: string;
  page?: number;
  pages?: number;
  size?: number;
}

export interface IMyLeadsParam {
  endDate?: string;
  startDate?: string;
}

export interface IMyClientsParams {
  page?: number;
  size?: number;
  orderByDesc?: string;
  orderBy?: string;
  fullName?: string;
  activeProgram?: number | number[];
  status?: string;
  VIP?: boolean;
  endDate?: string;
  startDate?: string;
  typeReport?: 0 | 1 | 2;
  withPagination?: boolean;
}

export interface IMyClientsSearchParams {
  activeProgram?: number | number[];
  status?: string;
}

export interface IRowMyClients {
  id: number;
  fullName: string;
  activeProgram: string;
  programStartDate?: string;
  lastLoginDate?: string;
  loginsPast30Days?: number;
  status?: string;
  redirect: number;
}

export interface IRowMyClientsReports {
  id: number;
  fullName: string;
  dateOfBirth?: string;
  phone: string;
  email: string;
  vipCancellationDate?: string;
  stars?: number;
}

export interface IActivePrograms {
  id: number;
  name: string;
  value: string;
}

export interface IBadgeUserTotalStart {
  fileUrl?: string;
  totalStarts?: number;
}

export interface IUserProfile {
  fullName?: string;
  userBlobFileUrl?: string;
  phone?: string;
  email?: string;
  age?: number;
  dateOfBirth?: string;
  gender?: string;
  language?: string;
  height?: string;
  startingWeight?: string;
  dietType?: string;
  fastingGoal?: string;
  badgeUserTotalStart?: IBadgeUserTotalStart;
}

export interface IAppOnboarding {
  activeProgram?: string;
  activeProgramStartDate?: string;
  appOnboardingDate?: string;
  vipStartDate?: string;
  lastLoginDate?: string;
  cancelDate?: string;
  startingMotivation?: string[];
  medicalConditions?: string[];
  dailyChallenges?: string[];
  activityLevel?: string;
  exerciseDay?: number;
  mainGoal?: string;
}

export interface IFoodDay {
  name?: string;
  id?: number;
}

export interface IItemMacroPlan {
  foodDayType?: IFoodDay;
  carbs?: number;
  fat?: number;
  protein?: number;
  unit?: string;
}

export interface IMacroPlan {
  carbs?: number;
  carbsMin?: number;
  created?: string;
  fat?: number;
  fatMin?: number;
  id?: number;
  lowCarbCoefficient?: number;
  protein?: number;
  proteinMin?: number;
  standard?: boolean;
  title?: string;
  updated?: string;
}

export interface IProfile {
  userProfile?: IUserProfile;
  appOnboarding?: IAppOnboarding;
  macroPlan?: IItemMacroPlan[];
  selectedMacroPlan?: IMacroPlan;
}

export interface IClientCSVData {
  fullName: string;
  activeProgram: string;
  programStartDate?: string;
  lastLoginDate?: string;
  loginsPast30Days: string;
  status?: string;
  phone?: string;
  dateOfBirth?: string;
  email?: string;
  vipCancellationDate?: string;
}

export interface IMyLeadsCSVData {
  date: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  source: string;
}
