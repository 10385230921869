import { CellContext } from "@tanstack/react-table";

export const columnTypes = {
  DATE: "date",
  HOUR: "hour",
  CUSTOM: "custom",
  STATUS: "status",
};

export type ColumnType = (typeof columnTypes)[keyof typeof columnTypes];

/**
 * name: to be display in the column header
 * value: the key to read the value in the API response to print in the column cell
 * mobile: a boolean to identify if this column will be display or not when the screen size is smaller than the table prop called 'mobileBreakpoint'
 * sortable: a boolean to identify if the column will have or not the sorting functionality
 * type: the column type from ColumnType to be treated according.
 * minWidth: expressed in rem
 * statusCond: used to draw a chip column with 2 status. 'active' and 'cancelled' are for define the value of the cell to draw the appropriate chip.
 */
export interface Column {
  name: string;
  value: string;
  mobile: boolean;
  align?: "right" | "left" | "center";
  sortable?: boolean;
  type?: ColumnType;
  renderHeader?: () => void;
  renderCell?: (cell: CellContext<object, unknown> | undefined) => void;
  minWidth?: number;
}

export interface TableOptions {
  sortBy: string | undefined;
  sortByDesc: string | undefined;
  page: number;
  pageSize: number;
}
