import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, InputAdornment, styled } from "@mui/material";
import { IAutocompleteProps, ENUMIconPosition, IOption } from "../../constants/interfaces";
import { observer } from "mobx-react-lite";

interface AutocompleteRenderOptionState {
  index: number;
  inputValue: string;
  selected: boolean;
}

const InputAutocomplete = observer(
  ({
    options,
    value,
    label,
    placeholder,
    multiple = false,
    icon: Icon,
    iconPosition = ENUMIconPosition.START,
    sx = { width: 200 },
    onChange,
    id,
  }: IAutocompleteProps) => {
    const [inputValue, setInputValue] = useState<IOption[] | IOption | null>(multiple ? [] : null);

    const GroupHeader = styled("div")(({ theme }) => ({
      position: "sticky",
      top: "-8px",
      padding: "4px 10px",
      color: theme.palette.primary.main,
      backgroundColor: "#f6f9fd",
    }));

    const GroupItems = styled("ul")({
      padding: 0,
    });

    const handleChange = (event: React.SyntheticEvent, newValue: IOption | IOption[] | null) => {
      setInputValue(newValue);
      if (onChange) {
        onChange(event, newValue);
      }
    };

    const renderOption = (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: IOption,
      item: AutocompleteRenderOptionState
    ) => {
      return (
        <li {...props} key={item?.index ?? 0}>
          {option.label}
        </li>
      );
    };

    useEffect(() => {
      value && setInputValue(value);
    }, [value]);

    return (
      <Autocomplete
        renderOption={renderOption}
        groupBy={(option) => option?.group || ""}
        renderGroup={(params) => (
          <li key={params.key}>
            {params.group ? <GroupHeader>{params.group}</GroupHeader> : null}
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        id={id}
        size="small"
        disablePortal
        multiple={multiple}
        value={inputValue}
        onChange={handleChange}
        options={options}
        getOptionLabel={(options) => options?.label}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        ListboxProps={{ style: { maxHeight: "20rem", fontSize: "1.4rem" } }}
        ChipProps={{ style: { fontSize: "1rem" } }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {Icon && iconPosition === ENUMIconPosition.START && (
                    <InputAdornment position="start">
                      <Icon fontSize="large" />
                    </InputAdornment>
                  )}
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <>
                  {Icon && iconPosition === ENUMIconPosition.END && (
                    <InputAdornment position="end">
                      <Icon />
                    </InputAdornment>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={{
              ...sx,
              "& .MuiChip-root": {
                maxWidth: "calc(100% - 4rem)",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
              "& .MuiOutlinedInput-root": {
                paddingRight: "5.5rem !important",
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "1.5rem",
              },
            }}
          />
        )}
        sx={sx}
      />
    );
  }
);

export default InputAutocomplete;
